<template>
    <main id="liste" >
        <HeaderTab 
            :title="$t('invoice.devis')" 
        />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <CustomTable
                                id_table="addressed_quotations"
                                :checkboxes="false"
                                :items="items"
                                :busy.sync="working"
                                :sortDesc="false"
                                sortBy="quotation_created"
                                primaryKey="quotation_id"
                                :hasExportOption="false"
                                :showPagination="false"
				                :externSlotColumns="['preview','download','quotation_status']"
				                :columsAddBegin="['preview','download']"
                            >
                                <template v-slot:[`custom-slot-cell(preview)`]="{ data }">
                                    <a href="#" @click.prevent="quickPreview(data)">
                                        <font-awesome-icon :icon="['fas', 'eye']" />
                                    </a>
                                </template>
                                <template v-slot:[`custom-slot-cell(download)`]="{ data }">
                                    <a href="#" @click.prevent="downloadQuotation(data)">
                                        <font-awesome-icon v-if="refresh_process && processing_tab[data.quotation_id]" pulse :icon="['fal', 'spinner']" />
                                        <font-awesome-icon v-else :icon="['fal', 'download']" />
                                    </a>
                                </template>
				                <template v-slot:[`custom-slot-cell(quotation_status)`]="{ data }">
				                    {{ $t('invoice.devis_status.'+data.quotation_status) }}
				                </template>
	                        </CustomTable>

				            <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
				                <template v-slot:modal-title>
				                    {{ $t("action.previsualiser") }}
				                </template>

				                <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
				                <div v-else>
				                    <LoadingSpinner />
				                </div>
				            </b-modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Invoice from '@/mixins/Invoice.js'
    import Navigation from '@/mixins/Navigation.js'
    import Common from '@/assets/js/common.js'

	export default {
        name: "QuotationsAddressed",
        mixins:[Invoice, Navigation],
		data () {
			return {
                items: null,
                working: false,
                base64: null,
                processing_tab: {},
                refresh_process: false
			}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {
                this.working = true

                this.getAllQuotation()
                .then(r => {
                    this.items = r
                    r.forEach(q => {
                        this.processing_tab[q.quotation_id] = false
                    })
                })
                .catch(e => {
                    this.failureToast("toast.info_lecture_failed")
                    console.error("InvoiceAddressed::init_component => Error:", e)
                })
                .finally(() => {
                    this.working = false
                })                
            },
            async quickPreview(quotation) {
                this.$refs.modelPreview.show()
                let retour = await this.getQuotationPdf(quotation.quotation_id, quotation.quotation_num, true)
                if(retour) {
                    this.base64 = retour
                }
            },
            async downloadQuotation(quotation) {
                this.refresh_process = true
                this.processing_tab[quotation.quotation_id] = true
                let retour = await this.getQuotationPdf(quotation.quotation_id, quotation.quotation_num, true)
                Common.base64ToPdf(retour, quotation.quotation_num + ".pdf")
                this.processing_tab[quotation.quotation_id] = false
                this.refresh_process = false
            }
        },

		components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}
</script>
